<script>
import useVuelidate from '@vuelidate/core'
import {required, maxLength} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCriseService from "@services/bilanCriseService";
import bilanCrisePeriodService from "@services/bilanCrisePeriodService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormView, AkFormSubmitted, AkInputText, AkInputTextArea, AkDropdown},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan_crise.update",
    }
  },
  data() {
    return {
      submitted: false,
      crisePeriodList: [],
      current: {},
    }
  },
  validations() {
    return {
      current: {
        crisePeriodId: {required},
        issue: {maxLength: maxLength(1000)},
        solution: {maxLength: maxLength(1000)},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p1 = bilanCriseService.findById(this.$route.params.id);
    p1.then(data => this.current = data);
    
    let p2 = bilanCrisePeriodService.findAll();
    p2.then(data => this.crisePeriodList = data);
  
    let p3 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.crisisReview.edit);

    Promise.all([p1, p2, p3]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {
   update() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.update"));
        return;
      }
      
      if(this.current.dateEnd<this.current.dateStart) {
        this.getRef().error(this.$t("bilan.error.dates"));
        return;
      }

      this.getRef().showLoader(this.$refs.form)
      bilanCriseService.update(this.current).then(data => {
        let msg = this.$t("bilan.updated");
        this.$router.replace({ path: `/bilan/crise/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
        }
      );
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error; 
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_crise.update')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-4" :label="$t('ager_label')"/>
          <AkInputText :disabled=true v-model=current.uerLabel class-name="col-md-4" :label="$t('uer_label')"/>
          <AkInputText :disabled=true v-model=current.ceiLabel class-name="col-md-4" :label="$t('cei_label')"/>
        </div>
        <div class="form-row">
          <AkDropdown :label="$t('bilan_crise.period')"
                      v-model="current.crisePeriodId"
                      :validator="v$.current.crisePeriodId"
                      :submitted=this.submitted
                      :options=this.crisePeriodList
                      option-value="id"
                      option-label="name"
                      class-name="col-md-4"/>
        </div>

        <h5>{{ $t('bilan_crise.input_text_area_title') }}</h5>

        <div class="form-row">
          <AkInputTextArea
              :rows=5
              v-model="current.issue"
              :validator="v$.current.issue"
              :submitted=this.submitted
              :label="$t('bilan_crise.issue')"
              class-name="col-md-6"/>
          <AkInputTextArea
              :rows=5
              v-model="current.solution"
              :validator="v$.current.solution"
              :submitted=this.submitted
              :label="$t('bilan_crise.solution')"
              class-name="col-md-6"/>
        </div>
        
        <div class="float-right">
          <button v-if=this.canEditCrisis() @click=update() class="btn btn-primary" style="margin-left: 10px">{{ $t('update') }}</button>
        </div>

      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>